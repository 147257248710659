// extracted by mini-css-extract-plugin
export var active = "data-preview-section-container-module--active--239b2";
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var barContainer = "data-preview-section-container-module--barContainer--9cf11";
export var barExpander = "data-preview-section-container-module--barExpander--d09f3";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var detailViewBack = "data-preview-section-container-module--detailViewBack--acf65";
export var detailViewButton = "data-preview-section-container-module--detailViewButton--46634";
export var detailViewIcon = "data-preview-section-container-module--detailViewIcon--527d1";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var fadeIn = "data-preview-section-container-module--fadeIn--f4450";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var header = "data-preview-section-container-module--header--26dce";
export var headerWrapper = "data-preview-section-container-module--headerWrapper--73764";
export var helpTextColor = "#d93b36";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var loadingIcon = "data-preview-section-container-module--loadingIcon--96980";
export var loadingSection = "data-preview-section-container-module--loadingSection--a3573";
export var mainWidth = "1140px";
export var noticeContainer = "data-preview-section-container-module--noticeContainer--3b072";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var sectionBorder = "data-preview-section-container-module--sectionBorder--c1510";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var tableContainer = "data-preview-section-container-module--tableContainer--9810a";
export var tableSection = "data-preview-section-container-module--tableSection--a3d0f";
export var titleContainer = "data-preview-section-container-module--titleContainer--939f6";