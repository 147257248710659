// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var apiUrl = "mobileFilterToggle-module--apiUrl--95cdf";
export var axisY = "mobileFilterToggle-module--axis--y--d3afa";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cancelButton = "mobileFilterToggle-module--cancelButton--c0e3d";
export var cardBody = "#555";
export var container = "mobileFilterToggle-module--container--078d4";
export var content = "mobileFilterToggle-module--content--bf08e";
export var contentBody = "mobileFilterToggle-module--contentBody--053a3";
export var contentSectionBackground = "#fff";
export var copyButton = "mobileFilterToggle-module--copyButton--74f42";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var ddpBodyBackground = "mobileFilterToggle-module--ddpBodyBackground--ccc51";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var domain = "mobileFilterToggle-module--domain--73b15";
export var downloadLink = "mobileFilterToggle-module--downloadLink--b3452";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var filterCount = "mobileFilterToggle-module--filterCount--e0d9d";
export var floatingToggle = "mobileFilterToggle-module--floatingToggle--7c918";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var hidden = "mobileFilterToggle-module--hidden--ba63c";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var inline = "mobileFilterToggle-module--inline--fc74f";
export var labelKey = "mobileFilterToggle-module--labelKey--86b35";
export var large = "mobileFilterToggle-module--large--6baaa";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContent = "mobileFilterToggle-module--mainContent--1eab9";
export var mainWidth = "1140px";
export var nothingSelected = "mobileFilterToggle-module--nothing-selected--c7d97";
export var pageHeader = "mobileFilterToggle-module--pageHeader--706fc";
export var pageWrapper = "mobileFilterToggle-module--pageWrapper--0f5d4";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var previewSection = "mobileFilterToggle-module--previewSection--fd73c";
export var primary = "mobileFilterToggle-module--primary--acb5a";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var required = "mobileFilterToggle-module--required--95d8f";
export var resetButton = "mobileFilterToggle-module--resetButton--90eeb";
export var resetButtondisabled = "mobileFilterToggle-module--resetButtondisabled--ba6f5";
export var resetIcon = "mobileFilterToggle-module--resetIcon--c85be";
export var searchBodyBackground = "mobileFilterToggle-module--searchBodyBackground--b8a87";
export var sectionContainer = "mobileFilterToggle-module--sectionContainer--d091e";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var smallText = "mobileFilterToggle-module--smallText--2e688";
export var srOnly = "mobileFilterToggle-module--sr-only--41ac2";
export var summaryText = "mobileFilterToggle-module--summaryText--d3be1";
export var techSpecs = "mobileFilterToggle-module--techSpecs--886d9";
export var textInput = "mobileFilterToggle-module--textInput--d0e0f";
export var tick = "mobileFilterToggle-module--tick--ebbfa";
export var title = "mobileFilterToggle-module--title--601f7";
export var toggleButton = "mobileFilterToggle-module--toggleButton--6a3b7";
export var toggleButtondisabled = "mobileFilterToggle-module--toggleButtondisabled--5aedb";
export var viz = "mobileFilterToggle-module--viz--4a2b7";