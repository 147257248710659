// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var card = "home-highlight-card-module--card--7a4fd";
export var cardActionArea = "home-highlight-card-module--cardActionArea--ab96e";
export var cardBody = "#555";
export var cardContent = "home-highlight-card-module--cardContent--10c6c";
export var cardHeaderLink = "home-highlight-card-module--cardHeaderLink--4f07e";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var datasetArrow = "home-highlight-card-module--datasetArrow--e7228";
export var datasetIcon = "home-highlight-card-module--datasetIcon--bcb20";
export var datasetLineLink = "home-highlight-card-module--datasetLineLink--32cc9";
export var datasetName = "home-highlight-card-module--datasetName--03452";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var header = "home-highlight-card-module--header--7633e";
export var helpTextColor = "#d93b36";
export var highlightLink = "home-highlight-card-module--highlightLink--83862";
export var imageContainer = "home-highlight-card-module--imageContainer--9414b";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var left = "home-highlight-card-module--left--7d0da";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainWidth = "1140px";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var right = "home-highlight-card-module--right--07b22";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var sparkLine = "home-highlight-card-module--sparkLine--e543c";
export var statDate = "home-highlight-card-module--statDate--c6421";
export var statLower = "home-highlight-card-module--statLower--94438";
export var statUpper = "home-highlight-card-module--statUpper--c1bf2";
export var statValue = "home-highlight-card-module--statValue--b7d48";
export var statsContainer = "home-highlight-card-module--statsContainer--603cb";
export var tooltip = "home-highlight-card-module--tooltip--34155";
export var tooltipLabel = "home-highlight-card-module--tooltipLabel--6b99d";
export var viewDataset = "home-highlight-card-module--viewDataset--95f8f";
export var xAxis = "home-highlight-card-module--xAxis--66351";
export var xLabelText = "home-highlight-card-module--x-label-text--f286f";