// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breadCrumbsContainer = "spending-categories-module--breadCrumbsContainer--e8ef4";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var chartBackdrop = "spending-categories-module--chartBackdrop--97214";
export var contentContainer = "spending-categories-module--contentContainer--c9b60";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var desktopSubNav = "spending-categories-module--desktopSubNav--82e92";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContainer = "spending-categories-module--mainContainer--70cdc";
export var mainContent = "spending-categories-module--mainContent--ff0c6";
export var mainWidth = "1140px";
export var mobileSubNav = "spending-categories-module--mobileSubNav--ebbeb";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var quoteBoxContent = "spending-categories-module--quoteBoxContent--f7c01";
export var relatedDatasets = "spending-categories-module--relatedDatasets--97808";
export var relatedDatasetsStyle = "spending-categories-module--relatedDatasetsStyle--85b00";
export var section = "spending-categories-module--section--eeb39";
export var sectionBorder = "spending-categories-module--sectionBorder--5878f";
export var sectionHeading = "spending-categories-module--sectionHeading--d70ba";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialShare = "spending-categories-module--socialShare--a5cb5";
export var socialShareContainer = "spending-categories-module--socialShareContainer--4ccb3";
export var sourceURL = "spending-categories-module--sourceURL--ee23f";
export var spendingCategoriesContent = "spending-categories-module--spendingCategoriesContent--17b54";
export var visWithCallout = "spending-categories-module--visWithCallout--88fe4";