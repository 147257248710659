// extracted by mini-css-extract-plugin
export var DebtOverLast100yContainer = "afg-overview-module--DebtOverLast100yContainer--5c56b";
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var blueText = "afg-overview-module--blueText--4d852";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var bottomContainer = "afg-overview-module--bottomContainer--25bf0";
export var bottomHeading = "afg-overview-module--bottomHeading--2d608";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var citation = "afg-overview-module--citation--85dbe";
export var constitutionImg = "afg-overview-module--constitutionImg--6022c";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtAccordion = "afg-overview-module--debtAccordion--7e556";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var debtText = "afg-overview-module--debtText--41f52";
export var deficitAccordion = "afg-overview-module--deficitAccordion--6d75e";
export var deficitExplainerLightSecondary = "#e0baab";
export var deficitExplainerPrimary = "#b3532d";
export var deficitExplainerSecondary = "#9f4224";
export var deficitText = "afg-overview-module--deficitText--b5b9b";
export var disabledButtonBg = "#94beda";
export var dollarIconBackgroundColor = "#f1f1f1";
export var dollarIconColor = "#666";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var footerContainer = "afg-overview-module--footerContainer--0d693";
export var growingNationalDebtSectionGraph = "afg-overview-module--growingNationalDebtSectionGraph--b8502";
export var growingNationalDebtSectionGraphContainer = "afg-overview-module--growingNationalDebtSectionGraphContainer--e09f5";
export var header = "afg-overview-module--header--9bd41";
export var headerContainer = "afg-overview-module--headerContainer--78372";
export var helpTextColor = "#d93b36";
export var icon = "afg-overview-module--icon--70ee2";
export var iconBackground = "afg-overview-module--iconBackground--7c8b4";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var keyTakeawaysContent = "afg-overview-module--keyTakeawaysContent--4632f";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContainer = "afg-overview-module--mainContainer--b9477";
export var mainWidth = "1140px";
export var middleHeader = "afg-overview-module--middleHeader--9151a";
export var middleHeaderHeading = "afg-overview-module--middleHeaderHeading--c7306";
export var middleHeaderHeadingContainer = "afg-overview-module--middleHeaderHeadingContainer--51e01";
export var middleHeaderIcon = "afg-overview-module--middleHeaderIcon--d8e85";
export var noMarginBottom = "afg-overview-module--noMarginBottom--847c6";
export var offsetIcon = "afg-overview-module--offsetIcon--ca551";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var postGraphAccordionContainer = "afg-overview-module--postGraphAccordionContainer--a61ba";
export var postGraphContent = "afg-overview-module--postGraphContent--d8460";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var quote = "afg-overview-module--quote--e34dd";
export var quoteBar = "afg-overview-module--quoteBar--88488";
export var quoteContainer = "afg-overview-module--quoteContainer--596d4";
export var quoteIcon = "afg-overview-module--quoteIcon--af108";
export var quoteSection = "afg-overview-module--quoteSection--4fc20";
export var revenueExplainerPrimary = "#0a2f5a";
export var semiBoldWeight = "600";
export var simple = "afg-overview-module--simple--2f6c7";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var socialShare = "afg-overview-module--socialShare--8576f";
export var subHeader = "afg-overview-module--subHeader--0f414";
export var subTitle = "afg-overview-module--subTitle--249a5";
export var title = "afg-overview-module--title--03939";
export var titleBreakdown = "afg-overview-module--titleBreakdown--70ec9";
export var topContainer = "afg-overview-module--topContainer--c55a6";
export var treasuryReportImg = "afg-overview-module--treasuryReportImg--dbaa4";