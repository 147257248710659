// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiErrorStyle = "data-preview-table-module--apiErrorStyle--fe414";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var fadeIn = "data-preview-table-module--fadeIn--a6929";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var formattedCell = "data-preview-table-module--formattedCell--fdff4";
export var helpTextColor = "#d93b36";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var loadingIcon = "data-preview-table-module--loadingIcon--d0e8b";
export var loadingSection = "data-preview-table-module--loadingSection--690b7";
export var mainWidth = "1140px";
export var noBorderStyle = "data-preview-table-module--noBorderStyle--4d259";
export var overlay = "data-preview-table-module--overlay--9373e";
export var overlayContainer = "data-preview-table-module--overlayContainer--b4352";
export var overlayContainerNoFooter = "data-preview-table-module--overlayContainerNoFooter--85e63";
export var overlayContainerReactTableHeight = "data-preview-table-module--overlayContainerReactTableHeight--020e3";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var rowsShowingStyle = "data-preview-table-module--rowsShowingStyle--e8e10";
export var selectColumnPanel = "data-preview-table-module--selectColumnPanel--9e8c1";
export var selectColumnPanelActive = "data-preview-table-module--selectColumnPanelActive--e1cf9";
export var selectColumnsWrapper = "data-preview-table-module--selectColumnsWrapper--c69e4";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var tableFooter = "data-preview-table-module--tableFooter--3fe21";
export var wrapper = "data-preview-table-module--wrapper--e183b";