// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var apiUrl = "page-scroll-link-module--apiUrl--74901";
export var axisY = "page-scroll-link-module--axis--y--2dfb1";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var content = "page-scroll-link-module--content--b33e3";
export var contentBody = "page-scroll-link-module--contentBody--25863";
export var contentSectionBackground = "#fff";
export var copyButton = "page-scroll-link-module--copyButton--35c99";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var ddpBodyBackground = "page-scroll-link-module--ddpBodyBackground--3d71c";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var domain = "page-scroll-link-module--domain--6be40";
export var downloadLink = "page-scroll-link-module--downloadLink--12fb8";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var helpTextColor = "#d93b36";
export var hidden = "page-scroll-link-module--hidden--67bcc";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var inline = "page-scroll-link-module--inline--04446";
export var labelKey = "page-scroll-link-module--labelKey--63dca";
export var large = "page-scroll-link-module--large--f2ff8";
export var lightWeight = "300";
export var linkColor = "#2272ce";
export var mainContent = "page-scroll-link-module--mainContent--d42a1";
export var mainWidth = "1140px";
export var nothingSelected = "page-scroll-link-module--nothing-selected--50d2c";
export var pageHeader = "page-scroll-link-module--pageHeader--6fc53";
export var pageWrapper = "page-scroll-link-module--pageWrapper--779c5";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var previewSection = "page-scroll-link-module--previewSection--faf30";
export var primary = "page-scroll-link-module--primary--a8358";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var required = "page-scroll-link-module--required--c9f77";
export var scrollLink = "page-scroll-link-module--scrollLink--50176";
export var searchBodyBackground = "page-scroll-link-module--searchBodyBackground--66ad9";
export var sectionContainer = "page-scroll-link-module--sectionContainer--a16e1";
export var semiBoldWeight = "600";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var smallText = "page-scroll-link-module--smallText--1faa2";
export var srOnly = "page-scroll-link-module--sr-only--fc3e7";
export var summaryText = "page-scroll-link-module--summaryText--93618";
export var techSpecs = "page-scroll-link-module--techSpecs--d9434";
export var textInput = "page-scroll-link-module--textInput--1dd0e";
export var tick = "page-scroll-link-module--tick--07e7b";
export var title = "page-scroll-link-module--title--5c123";
export var viz = "page-scroll-link-module--viz--1ced6";