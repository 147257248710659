// extracted by mini-css-extract-plugin
export var altButtonActiveBg = "#eff4fb";
export var animationCrosshair = "debt-trends-over-time-chart-module--animationCrosshair--8be97";
export var animationDuration = "1000ms";
export var apiHeader = "#f6f6f6";
export var basicWarningBackground = "#fdfdec";
export var basicWarningBorder = "#edf079";
export var bodyBackground = "#f1f1f1";
export var boldWeight = "800";
export var borderColor = "#ddd";
export var breakpointLg = "992px";
export var breakpointMd = "768px";
export var breakpointSm = "600px";
export var breakpointXl = "1205px";
export var cardBody = "#555";
export var container = "debt-trends-over-time-chart-module--container--dc1ec";
export var contentSectionBackground = "#fff";
export var darkGreen = "#2e8540";
export var darkGreyBorder = "#979797";
export var darkPink = "#d72d79";
export var darkSlateBlue = "#112e51";
export var ddBorderColor = "#d6d7d9";
export var debtExplainerLightSecondary = "#b699c6";
export var debtExplainerPrimary = "#4a0072";
export var debtExplainerSecondary = "#3b005b";
export var disabledButtonBg = "#94beda";
export var dropdownActiveBackground = "#dff2f7";
export var errorBackground = "#fee";
export var errorLinkColor = "#801313";
export var errorTextColor = "#a02323";
export var focus = "#2b71b8";
export var fontBodyCopy = "#666";
export var fontSize_10 = ".625rem";
export var fontSize_12 = ".75rem";
export var fontSize_13 = ".8125rem";
export var fontSize_14 = ".875rem";
export var fontSize_15 = ".9375rem";
export var fontSize_16 = "1rem";
export var fontSize_18 = "1.125rem";
export var fontSize_20 = "1.25rem";
export var fontSize_22 = "1.375rem";
export var fontSize_24 = "1.5rem";
export var fontSize_28 = "1.75rem";
export var fontSize_32 = "2rem";
export var fontSize_36 = "2.25rem";
export var fontSize_40 = "2.5rem";
export var fontSize_48 = "3rem";
export var fontSize_56 = "3.5rem";
export var fontSize_64 = "4rem";
export var fontTitle = "#555";
export var footerContainer = "debt-trends-over-time-chart-module--footerContainer--b95e4";
export var growingNationalDebtSectionGraph = "debt-trends-over-time-chart-module--growingNationalDebtSectionGraph--c87e5";
export var header = "debt-trends-over-time-chart-module--header--54dca";
export var headerContainer = "debt-trends-over-time-chart-module--headerContainer--aa6de";
export var helpTextColor = "#d93b36";
export var inAnimation = "debt-trends-over-time-chart-module--inAnimation--31f41";
export var infoBoxBackground = "#f3fafc";
export var infoBoxBorder = "#bce8f1";
export var infoIcon = "#00a5d4";
export var lightWeight = "300";
export var lineChartContainer = "debt-trends-over-time-chart-module--lineChartContainer--77007";
export var linkColor = "#2272ce";
export var mainWidth = "1140px";
export var paleBg = "#f8f8f8";
export var placeholder = "#a8a8a8";
export var primary = "#0071bc";
export var primaryDarker = "#205493";
export var primaryGlass = "rgba(0,113,188,.3)";
export var primaryInactive = "rgba(8,114,185,.4)";
export var primaryLightBackground = "#a9ddf3";
export var primaryPale = "#f1f7f8";
export var semiBoldWeight = "600";
export var simple = "debt-trends-over-time-chart-module--simple--56e2b";
export var siteContentWidth = "1140px";
export var slateBlue = "#112e51";
export var subHeader = "debt-trends-over-time-chart-module--subHeader--a5a81";
export var subTitle = "debt-trends-over-time-chart-module--subTitle--f2b95";
export var title = "debt-trends-over-time-chart-module--title--3fb1b";
export var titleBreakdown = "debt-trends-over-time-chart-module--titleBreakdown--fd45b";